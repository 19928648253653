import {
  put, all, call, takeLatest, takeEvery,
} from 'redux-saga/effects';
import qs from 'qs';
import { changeLoadingState, setErrorMessage } from 'actions/setting/settingActionCreator';
import ResourceActionType from 'actions/resource/resourceActionType';

import {
  saveResourcePaymentMethods,
  saveResourceCountries,
  saveResourceSegments,
  saveResourceTags,
  saveResourceGameCategories,
  saveResourceUsers,
  saveResourcePaymentMethodCurrencies,
  saveResourceCurrencies,
  saveResourceProviders,
  saveResourceGameCategoriesInProviders,
  saveResourceGames,
  saveResourceReasons,
  saveResourceCampaigns,
  saveResourceTagCategories,
  saveResourceTagsNames,
  saveResourcePlayerTags,
  saveResourceVerticalCategoryReasons,
  saveResourceTagCategory,
  saveResourceBrand,
  saveResourcePartner,
  saveResourceNegativeBalanceExists,
  saveResourceBetshops,
  saveResourcePartnerTFAState,
  saveResourceGamesSegments,
  saveResourceCrossPlatformCampaigns,
  saveResourceCrossPlatformCampaignBonuses,
  changeResourceGamesLoadingState,
  saveResourcePromotions,
  saveResourcePromotionMachines,
  saveResourceBrandPeriodsLimits,
  saveResourceGameGroupsNames,
  saveResourceCurrenciesPrecisions,
  saveResourceBrandTaxDda,
  saveResourceFixedWithdrawalAmount,
  saveResourceProvidersAggregators,
  changeResourceTagsLoading,
  changeResourceGameCategoriesLoadingState,
  saveResourceSportsBookLayouts,
  saveResourceGridMachineDimensionTypes,
  saveResourceCustomPages,
  saveResourceCashierType,
  saveResourceReleaseNotes,
  saveResourceGameTypes,
  saveResourceDeepLinkPromotions,
  saveResourcePermissionGroups,
  saveResourceKycType,
  saveResourceDirectoryRules,
  saveResourceLobbyGroups,
  saveResourceGroupGames,
  changeResourceGroupGamesLoadingState,
  saveResourceCampaignsV2,
  saveResourceLobbyProviders,
  saveResourceCampaignsV2Units,
  saveResourceLobbyGames,
  saveResourceLobbyCategories,
  saveResourceCrossPlatformCampaignsV2Units,
  saveResourceLobbyJackpotSets,
} from 'actions/resource/resourceActionCreator';
import { getError } from 'utils/helpers';
import { LocalStorageService, NetworkService } from 'services';
import { AppConstants } from 'constants/index';

function* getPaymentMethods({ payload }) {
  const { isLoading } = payload;
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, Resource, PaymentMethods } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, PaymentMethods], options);
    yield put(saveResourcePaymentMethods(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getCountries({ payload }) {
  const { isLoading } = payload;
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, Resource, Countries } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, Countries], options);
    yield put(saveResourceCountries(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getSegments({ payload }) {
  const { isLoading } = payload;
  try {
    const { brandId } = payload;
    const { ReportingAdmin, Resource, Segments } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, Segments, brandId]);
    yield put(saveResourceSegments(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getTags({ payload }) {
  const { isLoading, key } = payload;
  try {
    const { brandId, params } = payload;
    const options = {
      params,
    };
    const { PlayersAdmin, Tags, Resource } = AppConstants.api;
    if (isLoading) {
      yield put(changeResourceTagsLoading(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Resource, brandId], options);
    yield put(saveResourceTags(data, key));
    if (isLoading) {
      yield put(changeResourceTagsLoading(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeResourceTagsLoading(false));
    }
  }
}

function* getGameCategories({ payload }) {
  const { isLoading } = payload;
  try {
    const { isProvider } = payload;
    const options = {
      data: payload.data,
    };
    const { ReportingAdmin, Resource, Categories } = AppConstants.api;
    if (isLoading) {
      yield put(changeResourceGameCategoriesLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, Resource, Categories], options);
    if (isProvider) {
      yield put(saveResourceGameCategoriesInProviders(data));
    } else {
      yield put(saveResourceGameCategories(data));
    }
    if (isLoading) {
      yield put(changeResourceGameCategoriesLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeResourceGameCategoriesLoadingState(false));
    }
  }
}

function* getUsers({ payload }) {
  const { isLoading } = payload;
  try {
    const { brandId } = payload;
    const { ReportingAdmin, Resource, Users } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, Users, brandId]);
    yield put(saveResourceUsers(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getPaymentMethodCurrencies({ payload }) {
  const { isLoading } = payload;
  try {
    const { id, params } = payload;
    const options = {
      params,
    };
    const {
      ReportingAdmin, Resource, PaymentMethods, Currencies,
    } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, PaymentMethods, id, Currencies], options);
    yield put(saveResourcePaymentMethodCurrencies(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getCurrencies({ payload }) {
  const { isLoading } = payload;
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { ReportingAdmin, Resource, Currencies } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, Currencies], options);
    yield put(saveResourceCurrencies(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getProviders({ payload }) {
  const { isLoading, key } = payload;
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { ReportingAdmin, Resource, Providers } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, Providers], options);
    yield put(saveResourceProviders(data, key));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getGames({ payload }) {
  try {
    const options = {
      data: payload,
    };
    yield put(changeResourceGamesLoadingState(true));
    const { ReportingAdmin, Resource, Games } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, Resource, Games], options);
    yield put(saveResourceGames(data));
    yield put(changeResourceGamesLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeResourceGamesLoadingState(false));
  }
}

function* getReasons({ payload }) {
  const { brandId, params, isLoading } = payload;
  try {
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };

    if (isLoading) {
      yield put(changeLoadingState(true));
    }

    const { ReportingAdmin, Resource, Reasons } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, Reasons, brandId], options);
    yield put(saveResourceReasons(data));

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getCampaigns({ payload }) {
  const { isLoading, brandId } = payload;
  try {
    const options = {
      params: { brandId },
    };
    const { BonusAdmin, Campaigns, Resource } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, Resource], options);
    yield put(saveResourceCampaigns(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getTagCategories({ payload }) {
  const { isLoading, key } = payload;
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { PlayersAdmin, TagCategories, Resource } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, TagCategories, Resource], options);
    yield put(saveResourceTagCategories(data, key));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getTagCategory({ payload }) {
  const { isLoading } = payload;
  try {
    const { id, params } = payload;
    const options = {
      params,
    };
    const { PlayersAdmin, TagCategories, Resource } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, TagCategories, Resource, id], options);
    yield put(saveResourceTagCategory(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getTagsNames({ payload }) {
  const { isLoading } = payload;
  try {
    const { brandId, params } = payload;
    const options = {
      params,
    };
    const {
      PlayersAdmin, Tags, Names, Resource,
    } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Names, Resource, brandId], options);
    yield put(saveResourceTagsNames(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getPlayerTags({ payload }) {
  const { isLoading } = payload;
  try {
    const { brandId, playerId, params } = payload;
    const options = {
      params,
    };
    const { PlayersAdmin, Tags, Resource } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, Tags, Resource, brandId, playerId], options);
    yield put(saveResourcePlayerTags(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getVerticalCategoryReasons({ payload }) {
  const { isLoading } = payload;
  try {
    const { params, key } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      PlayersAdmin, TagCategories, Reason, Resource,
    } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PlayersAdmin, TagCategories, Reason, Resource], options);
    yield put(saveResourceVerticalCategoryReasons(data, key));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getBrand({ payload }) {
  try {
    const { id } = payload;
    const { ReportingAdmin, Resource, Brand } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, Brand, id]);
    yield put(saveResourceBrand(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getPartner({ payload }) {
  try {
    const { id } = payload;
    const { ReportingAdmin, Resource, Partner } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, Partner, id]);
    yield put(saveResourcePartner(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getNegativeBalanceExists({ payload }) {
  try {
    const { brandId, params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, Resource, NegativeBalance } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, NegativeBalance, brandId], options);
    yield put(saveResourceNegativeBalanceExists(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getBetshops({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, Resource, Betshops } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, Betshops], options);
    yield put(saveResourceBetshops(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getPartnerTFAState({ payload }) {
  try {
    const { id } = payload;
    const { ReportingAdmin, Resource, TFAStatus } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, TFAStatus, id]);
    yield put(saveResourcePartnerTFAState(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* getGamesSegments({ payload }) {
  const { isLoading, params } = payload;
  const options = {
    params,
  };
  try {
    const { brandId } = payload;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const {
      Transaction, Game, Segments, Resource,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Game, Segments, brandId, Resource], options);
    yield put(saveResourceGamesSegments(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getCrossPlatformCampaigns({ payload }) {
  const { isLoading, params } = payload;
  const options = {
    params,
    paramsSerializer: (param) => qs.stringify(param),
  };
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { BonusAdmin, CampaignsCrossPlatform, Resource } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, Resource], options);
    yield put(saveResourceCrossPlatformCampaigns(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getCrossPlatformCampaignBonuses({ payload }) {
  try {
    const { id } = payload;
    const {
      BonusAdmin, CampaignsCrossPlatform, Bonuses, Resource,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, id, Bonuses, Resource]);
    yield put(saveResourceCrossPlatformCampaignBonuses(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPromotions({ payload }) {
  const { isLoading, params, key } = payload;
  const options = {
    params,
    paramsSerializer: (param) => qs.stringify(param),
  };
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { PromoAdmin, Resources, Promotions } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Resources, Promotions], options);

    yield put(saveResourcePromotions(data, key));

    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getPromotionMachines({ payload }) {
  const { isLoading, params, key } = payload;
  const options = {
    params,
  };
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { PromoAdmin, Resources, Machines } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Resources, Machines], options);
    yield put(saveResourcePromotionMachines(data, key));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getBrandPeriodsLimits({ payload }) {
  try {
    const { params, sourceId } = payload;
    const options = {
      params,
    };
    const {
      ReportingAdmin, Resource, ResponsibleGaming, LimitationPeriods,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, ResponsibleGaming, LimitationPeriods, sourceId], options);
    yield put(saveResourceBrandPeriodsLimits(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getGameGroupsNames({ payload }) {
  const { params, isLoading } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const options = {
      params,
    };
    const {
      ReportingAdmin, Resource, Game, Groups, Names,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, Game, Groups, Names], options);
    yield put(saveResourceGameGroupsNames(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getCurrenciesPrecisions({ payload }) {
  const { isLoading } = payload;
  try {
    const { BrandAdmin, Currency, Precisions } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Currency, Precisions]);
    LocalStorageService.set('currenciesPrecisions', data);
    yield put(saveResourceCurrenciesPrecisions(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getResourceBrandTaxDda({ payload }) {
  const { brandId, isLoading } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const {
      BrandAdmin, Taxes, DdaWithdrawal, Resource,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Taxes, DdaWithdrawal, brandId, Resource]);
    yield put(saveResourceBrandTaxDda(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getResourceFixedWithdrawalAmount({ payload }) {
  const { brandId, isLoading } = payload;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const {
      BrandAdmin, Taxes, FixedWithdrawalAmount, Resource,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Taxes, FixedWithdrawalAmount, brandId, Resource]);
    yield put(saveResourceFixedWithdrawalAmount(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getProvidersAggregators({ payload }) {
  const { isLoading } = payload;
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { ReportingAdmin, Resource, Aggregators } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, Aggregators], options);
    yield put(saveResourceProvidersAggregators(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getSportsBookLayouts() {
  try {
    yield put(changeLoadingState(true));
    const {
      BrandAdmin, Provider, Sportsbook, ProviderViews, Resource,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Provider, Sportsbook, ProviderViews, Resource]);
    yield put(saveResourceSportsBookLayouts(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getGridMachineDimensionTypes() {
  try {
    const { PromoAdmin, Resources, GridMachineDimensionTypes } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Resources, GridMachineDimensionTypes]);
    yield put(saveResourceGridMachineDimensionTypes(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCustomPages({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { BuilderAdmin, Page, Resource } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BuilderAdmin, Page, Resource], options);
    yield put(saveResourceCustomPages(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashierType({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, Resource, Cashier } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, Cashier, params.brandId], options);
    yield put(saveResourceCashierType(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getReleaseNotes() {
  try {
    const { ReportingAdmin, Resource, ReleaseNotes } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, ReleaseNotes]);
    yield put(saveResourceReleaseNotes(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getGameTypes({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { ReportingAdmin, Resource, GameTypes } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, GameTypes], options);
    yield put(saveResourceGameTypes(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getDeepLinkPromotions({ payload }) {
  try {
    const { params } = payload;
    const options = { params };
    const { PromoAdmin, Resources, DeepLinkPromotions } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [PromoAdmin, Resources, DeepLinkPromotions], options);
    yield put(saveResourceDeepLinkPromotions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPermissionGroups({ payload }) {
  const { isLoading, params, key } = payload;

  try {
    const options = {
      params,
    };

    const { Admin, PermissionGroup, Resource } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Admin, PermissionGroup, Resource], options);
    yield put(saveResourcePermissionGroups(data, key));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getKycType({ payload }) {
  const { params } = payload;
  const options = {
    params,
  };
  const { ReportingAdmin, Resource, KYC } = AppConstants.api;
  try {
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, KYC], options);
    yield put(saveResourceKycType(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getDirectoryRules({ payload }) {
  const { params, path, isLoading } = payload;
  const options = {
    params,
  };
  const { BrandAdmin, Resources, Directories } = AppConstants.api;
  try {
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BrandAdmin, Resources, Directories, path], options);
    yield put(saveResourceDirectoryRules(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getLobbyGroups({ payload }) {
  try {
    const { lobbyId, params } = payload;
    const options = { params };
    yield put(changeLoadingState(true));
    const { Transaction, Lobbies, GameGroups } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, GameGroups], options);
    yield put(saveResourceLobbyGroups(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignsV2({ payload }) {
  const { isLoading, params } = payload;
  try {
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, V2, Resource,
    } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, Resource], options);
    yield put(saveResourceCampaignsV2(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getLobbyProviders({ payload }) {
  const { isLoading } = payload;
  try {
    const { lobbyId, params } = payload;
    const options = {
      params,
    };
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const {
      ReportingAdmin, Resource, Lobbies, Providers,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, Lobbies, lobbyId, Providers], options);
    yield put(saveResourceLobbyProviders(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getLobbyCategories({ payload }) {
  const { isLoading } = payload;
  try {
    const { lobbyId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const {
      ReportingAdmin, Resource, Lobbies, Categories,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, Resource, Lobbies, lobbyId, Categories], options);
    yield put(saveResourceLobbyCategories(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getLobbyGames({ payload }) {
  const { isLoading } = payload;
  try {
    const { lobbyId, params, key } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeResourceGamesLoadingState(true));
    const {
      ReportingAdmin, Resource, Lobbies, Games,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIPostRequest, [ReportingAdmin, Resource, Lobbies, lobbyId, Games], options);
    yield put(saveResourceLobbyGames(data, key));
    if (isLoading) {
      yield put(changeResourceGamesLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeResourceGamesLoadingState(false));
    }
  }
}

function* getGroupGames({ payload }) {
  try {
    const { groupId, params } = payload;
    const options = {
      params,
    };
    yield put(changeResourceGroupGamesLoadingState(true));
    const {
      ReportingAdmin, Resource, Groups, Games,
    } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, Groups, groupId, Games], options);
    yield put(saveResourceGroupGames(data));
    yield put(changeResourceGroupGamesLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeResourceGroupGamesLoadingState(false));
  }
}

function* getCampaignsV2Units({ payload }) {
  const { isLoading, params } = payload;
  try {
    const options = {
      params,
    };
    const {
      BonusAdmin, Campaigns, V2, Units, Resource,
    } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, Units, Resource], options);
    yield put(saveResourceCampaignsV2Units(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getCrossPlatformCampaignsV2Units({ payload }) {
  const { isLoading, params, campaignId } = payload;
  try {
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Units, Resource,
    } = AppConstants.api;
    if (isLoading) {
      yield put(changeLoadingState(true));
    }
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Units, Resource], options);
    yield put(saveResourceCrossPlatformCampaignsV2Units(data));
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    if (isLoading) {
      yield put(changeLoadingState(false));
    }
  }
}

function* getLobbyJackpotSets({ payload }) {
  const { params } = payload;
  try {
    const options = {
      params,
    };
    const {
      ReportingAdmin, Resource, Jackpot, Sets,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [ReportingAdmin, Resource, Jackpot, Sets], options);
    yield put(saveResourceLobbyJackpotSets(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* resourceSaga() {
  yield all([
    takeLatest(ResourceActionType.GET_RESOURCE_PAYMENT_METHODS, getPaymentMethods),
    takeLatest(ResourceActionType.GET_RESOURCE_COUNTRIES, getCountries),
    takeLatest(ResourceActionType.GET_RESOURCE_SEGMENTS, getSegments),
    takeEvery(ResourceActionType.GET_RESOURCE_TAGS, getTags),
    takeLatest(ResourceActionType.GET_RESOURCE_GAME_CATEGORIES, getGameCategories),
    takeLatest(ResourceActionType.GET_RESOURCE_USERS, getUsers),
    takeLatest(ResourceActionType.GET_RESOURCE_PAYMENT_METHOD_CURRENCIES, getPaymentMethodCurrencies),
    takeLatest(ResourceActionType.GET_RESOURCE_CURRENCIES, getCurrencies),
    takeLatest(ResourceActionType.GET_RESOURCE_PROVIDERS, getProviders),
    takeLatest(ResourceActionType.GET_RESOURCE_GAMES, getGames),
    takeLatest(ResourceActionType.GET_RESOURCE_REASONS, getReasons),
    takeLatest(ResourceActionType.GET_RESOURCE_CAMPAIGNS, getCampaigns),
    takeLatest(ResourceActionType.GET_RESOURCE_TAG_CATEGORIES, getTagCategories),
    takeLatest(ResourceActionType.GET_RESOURCE_TAG_CATEGORY, getTagCategory),
    takeLatest(ResourceActionType.GET_RESOURCE_TAGS_NAMES, getTagsNames),
    takeLatest(ResourceActionType.GET_RESOURCE_PLAYER_TAGS, getPlayerTags),
    takeLatest(ResourceActionType.GET_RESOURCE_VERTICAL_CATEGORY_REASONS, getVerticalCategoryReasons),
    takeLatest(ResourceActionType.GET_RESOURCE_BRAND, getBrand),
    takeLatest(ResourceActionType.GET_RESOURCE_PARTNER, getPartner),
    takeLatest(ResourceActionType.GET_RESOURCE_BETSHOPS, getBetshops),
    takeLatest(ResourceActionType.GET_RESOURCE_NEGATIVE_BALANCE_EXISTS, getNegativeBalanceExists),
    takeLatest(ResourceActionType.GET_RESOURCE_PARTNER_TFA_STATE, getPartnerTFAState),
    takeLatest(ResourceActionType.GET_RESOURCE_GAMES_SEGMENTS, getGamesSegments),
    takeLatest(ResourceActionType.GET_RESOURCE_CROSS_PLATFORM_CAMPAIGNS, getCrossPlatformCampaigns),
    takeLatest(ResourceActionType.GET_RESOURCE_CROSS_PLATFORM_CAMPAIGN_BONUSES, getCrossPlatformCampaignBonuses),
    takeEvery(ResourceActionType.GET_RESOURCE_PROMOTIONS, getPromotions),
    takeLatest(ResourceActionType.GET_RESOURCE_PROMOTION_MACHINES, getPromotionMachines),
    takeLatest(ResourceActionType.GET_RESOURCE_BRAND_PERIODS_LIMITS, getBrandPeriodsLimits),
    takeLatest(ResourceActionType.GET_RESOURCE_GAME_GROUPS_NAMES, getGameGroupsNames),
    takeLatest(ResourceActionType.GET_RESOURCE_CURRENCIES_PRECISIONS, getCurrenciesPrecisions),
    takeLatest(ResourceActionType.GET_RESOURCE_BRAND_TAX_DDA, getResourceBrandTaxDda),
    takeLatest(ResourceActionType.GET_RESOURCE_FIXED_WITHDRAWAL_AMOUNT, getResourceFixedWithdrawalAmount),
    takeLatest(ResourceActionType.GET_RESOURCE_PROVIDERS_AGGREGATORS, getProvidersAggregators),
    takeLatest(ResourceActionType.GET_RESOURCE_SPORTSBOOK_LAYOUTS, getSportsBookLayouts),
    takeLatest(ResourceActionType.GET_RESOURCE_GRID_MACHINE_DIMENSION_TYPES, getGridMachineDimensionTypes),
    takeLatest(ResourceActionType.GET_RESOURCE_CUSTOM_PAGES, getCustomPages),
    takeLatest(ResourceActionType.GET_RESOURCE_CASHIER_TYPE, getCashierType),
    takeLatest(ResourceActionType.GET_RESOURCE_RELEASE_NOTES, getReleaseNotes),
    takeLatest(ResourceActionType.GET_RESOURCE_GAME_TYPES, getGameTypes),
    takeLatest(ResourceActionType.GET_RESOURCE_DEEP_LINK_PROMOTIONS, getDeepLinkPromotions),
    takeEvery(ResourceActionType.GET_RESOURCE_PERMISSION_GROUPS, getPermissionGroups),
    takeLatest(ResourceActionType.GET_RESOURCE_KYC_TYPE, getKycType),
    takeEvery(ResourceActionType.GET_RESOURCE_DIRECTORY_RULES, getDirectoryRules),
    takeLatest(ResourceActionType.GET_RESOURCE_LOBBY_GROUPS, getLobbyGroups),
    takeLatest(ResourceActionType.GET_RESOURCE_GROUP_GAMES, getGroupGames),
    takeLatest(ResourceActionType.GET_RESOURCE_CAMPAIGNS_V2, getCampaignsV2),
    takeLatest(ResourceActionType.GET_RESOURCE_LOBBY_PROVIDERS, getLobbyProviders),
    takeLatest(ResourceActionType.GET_RESOURCE_LOBBY_CATEGORIES, getLobbyCategories),
    takeEvery(ResourceActionType.GET_RESOURCE_LOBBY_GAMES, getLobbyGames),
    takeLatest(ResourceActionType.GET_RESOURCE_CAMPAIGNS_V2_UNITS, getCampaignsV2Units),
    takeLatest(ResourceActionType.GET_RESOURCE_CROSS_PLATFORM_CAMPAIGNS_V2_UNITS, getCrossPlatformCampaignsV2Units),
    takeLatest(ResourceActionType.GET_RESOURCE_LOBBY_JACKPOT_SETS, getLobbyJackpotSets),
  ]);
}
