import UserActionType from './userActionType';

export const getUsers = (params) => ({
  type: UserActionType.GET_USERS,
  payload: { params },
});

export const getUsersSuccess = ({ data, total }) => ({
  type: UserActionType.GET_USERS_SUCCESS,
  payload: { data, total },
});

export const resetUsers = () => ({
  type: UserActionType.RESET_USERS,
});

export const getUser = (id, isAuth) => ({
  type: UserActionType.GET_USER,
  payload: { id, isAuth },
});

export const saveUser = (data) => ({
  type: UserActionType.SAVE_USER,
  payload: { data },
});

export const resetUser = () => ({
  type: UserActionType.RESET_USER,
});

export const addUser = (data) => ({
  type: UserActionType.ADD_USER,
  payload: { data },
});

export const editUser = (userId, data) => ({
  type: UserActionType.EDIT_USER,
  payload: {
    userId,
    data,
  },
});

export const deleteUser = (id, name) => ({
  type: UserActionType.DELETE_USER,
  payload: { id, name },
});

export const changeUserLoadingState = (state) => ({
  type: UserActionType.CHANGE_USER_LOADING_STATE,
  payload: { state },
});

export const changeActivityStatus = (data) => ({
  type: UserActionType.CHANGE_ACTIVITY_STATUS,
  payload: { data },
});

export const getUserConfigs = (data) => ({
  type: UserActionType.GET_USER_CONFIGS,
  payload: { data },
});

export const saveUserConfigs = (data) => ({
  type: UserActionType.SAVE_USER_CONFIGS,
  payload: { data },
});

export const createOrEditUserConfig = (data, isLoading = true) => ({
  type: UserActionType.CREATE_OR_EDIT_USER_CONFIG,
  payload: { data, isLoading },
});

export const deleteUserConfig = (key) => ({
  type: UserActionType.DELETE_USER_CONFIG,
  payload: { key },
});

export const getUserPartnerAccesses = (userId) => ({
  type: UserActionType.GET_USER_PARTNER_ACCESSES,
  payload: { userId },
});

export const getUserPartnerAccessesSuccess = (data) => ({
  type: UserActionType.GET_USER_PARTNER_ACCESSES_SUCCESS,
  payload: { data },
});

export const resetUserPartnerAccesses = () => ({
  type: UserActionType.RESET_USER_PARTNER_ACCESSES,
});

export const getUserBrandAccesses = (userId) => ({
  type: UserActionType.GET_USER_BRAND_ACCESSES,
  payload: { userId },
});

export const getUserBrandAccessesSuccess = (data) => ({
  type: UserActionType.GET_USER_BRAND_ACCESSES_SUCCESS,
  payload: { data },
});

export const resetUserBrandAccesses = () => ({
  type: UserActionType.RESET_USER_BRAND_ACCESSES,
});

export const getUserPermissions = (data) => ({
  type: UserActionType.GET_USER_PERMISSIONS,
  payload: { data },
});

export const getUserPermissionsSuccess = (data) => ({
  type: UserActionType.GET_USER_PERMISSIONS_SUCCESS,
  payload: { data },
});

export const resetUserPermissions = () => ({
  type: UserActionType.RESET_USER_PERMISSIONS,
});

export const userAssignPermissions = (userId, data, name) => ({
  type: UserActionType.USER_ASSIGN_PERMISSIONS,
  payload: { userId, data, name },
});

export const getUserPermissionGroup = (data) => ({
  type: UserActionType.GET_USER_PERMISSION_GROUP,
  payload: { data },
});

export const getUserPermissionGroupSuccess = (data) => ({
  type: UserActionType.GET_USER_PERMISSION_GROUP_SUCCESS,
  payload: { data },
});

export const resetUserPermissionGroup = () => ({
  type: UserActionType.RESET_USER_PERMISSION_GROUP,
});

export const getUserBrandsByPartner = (partnerId, isLoading) => ({
  type: UserActionType.GET_USER_BRANDS_BY_PARTNER,
  payload: { partnerId, isLoading },
});

export const getUserBrandsByPartnerSuccess = (data) => ({
  type: UserActionType.GET_USER_BRANDS_BY_PARTNER_SUCCESS,
  payload: { data },
});

export const resetUserBrandsByPartner = () => ({
  type: UserActionType.RESET_USER_BRANDS_BY_PARTNER,
});

export const getUserPositions = (isLoading) => ({
  type: UserActionType.GET_USER_POSITIONS,
  payload: { isLoading },
});

export const getUserPositionsSuccess = (data) => ({
  type: UserActionType.GET_USER_POSITIONS_SUCCESS,
  payload: { data },
});

export const resetUserPositions = () => ({
  type: UserActionType.RESET_USER_POSITIONS,
});

export const getSupervisorUsers = (partnerId, params) => ({
  type: UserActionType.GET_SUPERVISOR_USERS,
  payload: { partnerId, params },
});

export const getSupervisorUsersSuccess = (data) => ({
  type: UserActionType.GET_SUPERVISOR_USERS_SUCCESS,
  payload: { data },
});

export const resetSupervisorUsers = () => ({
  type: UserActionType.RESET_SUPERVISOR_USERS,
});

export const editUsersActivityStatus = (data) => ({
  type: UserActionType.EDIT_USERS_ACTIVITY_STATUS,
  payload: { data },
});

export const deleteUsers = (data) => ({
  type: UserActionType.DELETE_USERS,
  payload: { data },
});

export const editUsers = (data) => ({
  type: UserActionType.EDIT_USERS,
  payload: { data },
});

export const bulkAssignPermissions = (data) => ({
  type: UserActionType.BULK_ASSIGN_PERMISSIONS,
  payload: { data },
});

export const resendQRCodesByBulk = (data) => ({
  type: UserActionType.RESEND_QR_CODES_BY_BULK,
  payload: { data },
});

export const unlockUser = (userId, name) => ({
  type: UserActionType.UNLOCK_USER,
  payload: { userId, name },
});

export const exportUsers = (params) => ({
  type: UserActionType.EXPORT_USERS,
  payload: { params },
});

export const saveUserConfigKey = (key, value) => ({
  type: UserActionType.SAVE_USER_CONFIG_KEY,
  payload: { key, value },
});
