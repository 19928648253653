import {
  all, call, put, takeLatest,
} from '@redux-saga/core/effects';
import qs from 'qs';
import CampaignV2ActionType from 'actions/campaignV2/campaignV2ActionType';
import { AppConstants } from 'constants/index';
import {
  changeCampaignV2BonusEndedStatus,
  changeCampaignV2GeneralConfigEndedStatus,
  changeCampaignV2ParticipationGroupEndedStatus,
  changeCampaignV2TriggerConditionEndedStatus,
  changeCampaignV2PublishEndedStatus,
  saveCampaignsV2,
  saveCampaignV2,
  saveCampaignV2FreeSpinBonus,
  saveCampaignV2ParticipationGroup,
  saveCampaignV2ParticipationGroups,
  saveCampaignV2FreeBetBonus,
  saveCampaignV2TriggerCondition,
  saveCampaignV2TriggerConditions,
  saveCreatedCampaignV2Id,
  saveCampaignV2Bonuses,
  saveCampaignV2SpecialBonus,
  saveCampaignV2CombinedBonus,
  changeCampaignV2BonusContentEndedStatus,
  saveCampaignV2BonusContents,
  changeCampaignV2CampaignContentEndedStatus,
  saveCampaignV2CampaignContents,
  saveCampaignV2CampaignContent,
  saveCampaignV2BonusContent,
  saveCampaignV2Units,
  saveCampaignV2Unit,
  changeCampaignV2UnitEndedStatus,
  saveCampaignV2OffersContents,
  changeCampaignV2OffersContentEndedStatus,
  saveCampaignV2OffersContent,
  saveCampaignV2Statistics,
  saveCampaignsV2Units,
  saveCampaignV2UnitPlayers,
  saveCampaignV2FreeBetBonuses,
  saveCampaignV2SpecialBonuses,
  saveCampaignV2SpinBonuses,
  saveCampaignV2WagerBonuses,
  saveCrossPlatformCampaignsV2,
  saveCrossPlatformCampaignV2,
  saveCrossPlatformCampaignV2Bonuses,
  saveCrossPlatformCampaignV2SpecialBonus,
  saveCrossPlatformCampaignV2CombinedBonus,
  saveCrossPlatformCampaignV2FreeBetBonus,
  saveCrossPlatformCampaignV2FreeSpinBonus,
  saveCrossPlatformCampaignV2BonusContents,
  saveCrossPlatformCampaignV2BonusContent,
  saveCrossPlatformCampaignV2Units,
  saveCrossPlatformCampaignV2Unit,
  saveCrossPlatformCampaignV2Statistics,
} from 'actions/campaignV2/campaignV2ActionCreator';
import {
  setSuccessMessage, changeEndedStatus, setErrorMessage, changeLoadingState,
} from 'actions/setting/settingActionCreator';
import { getMessage, getError } from 'utils/helpers';
import i18n from 'services/i18n';

import NetworkService from 'services/networkService';
import mime from 'mimetypes';
import { downloadFile } from 'utils/utils';
import enumTypes from 'constants/enumTypes';

function* getCampaigns({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, Campaigns, V2 } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2], options);
    yield put(saveCampaignsV2(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaign({ payload }) {
  try {
    const { id } = payload;
    const { BonusAdmin, Campaigns, V2 } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id]);
    yield put(saveCampaignV2(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCampaign({ payload }) {
  try {
    const { name } = payload.data;
    const { BonusAdmin, Campaigns, V2 } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2], payload);
    const { id } = data;
    yield put(saveCreatedCampaignV2Id(id));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slCreated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCampaign({ payload }) {
  try {
    const { id, data } = payload;
    const { BonusAdmin, Campaigns, V2 } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, id], { data });
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:campaign'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createParticipationGroup({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, ParticipationConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, ParticipationConditions], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lParticipationGroup'), i18n.t('notification:slCreated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeCampaignV2ParticipationGroupEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editParticipationGroup({ payload }) {
  try {
    const { campaignId, id, data } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, ParticipationConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, ParticipationConditions, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:participationGroup'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2ParticipationGroupEndedStatus(true));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getParticipationGroup({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, Campaigns, V2, ParticipationConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, campaignId, ParticipationConditions, id], payload);
    yield put(changeLoadingState(false));
    yield put(saveCampaignV2ParticipationGroup(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getParticipationGroups({ payload }) {
  try {
    const { id } = payload;
    const {
      BonusAdmin, Campaigns, V2, ParticipationConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, ParticipationConditions], payload);
    yield put(changeLoadingState(false));
    yield put(saveCampaignV2ParticipationGroups(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteParticipationGroup({ payload }) {
  try {
    const { campaignId, id, name } = payload;
    const {
      BonusAdmin, Campaigns, V2, ParticipationConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, ParticipationConditions, id], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lParticipationGroup'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
    yield put(changeCampaignV2ParticipationGroupEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getFreeSpinBonus({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Spins,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Spins, id]);
    yield put(saveCampaignV2FreeSpinBonus(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getFreeBetBonus({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, Campaigns, V2, FreeBets, Bonuses,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, FreeBets, id]);
    yield put(saveCampaignV2FreeBetBonus(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createFreeSpinBonus({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Spins,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Spins], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slCreated'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createFreeBetBonus({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, FreeBets, Bonuses,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, FreeBets], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slCreated'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editFreeSpinBonus({ payload }) {
  try {
    const { campaignId, data, id } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Spins,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Spins, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:bonus'), i18n.t('notification:slUpdated'))));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editFreeBetBonus({ payload }) {
  try {
    const { campaignId, id, data } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Bonuses, FreeBets,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, FreeBets, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:bonus'), i18n.t('notification:slUpdated'))));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getBonuses({ payload }) {
  try {
    const { id } = payload;
    const {
      BonusAdmin, Campaigns, V2, Bonuses,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, Bonuses], payload);
    yield put(changeLoadingState(false));
    yield put(saveCampaignV2Bonuses(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteFreeBetBonus({ payload }) {
  try {
    const { campaignId, id, name } = payload;
    const {
      BonusAdmin, Campaigns, V2, Bonuses, FreeBets,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, FreeBets, id], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slDeleted'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteFreeSpinBonus({ payload }) {
  try {
    const { campaignId, id, name } = payload;
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Spins,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Spins, id], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slDeleted'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCombinedBonus({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Combined, Bonuses,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Combined], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slCreated'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCombinedBonus({ payload }) {
  try {
    const { campaignId, id, data } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Combined,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Combined, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:bonus'), i18n.t('notification:slUpdated'))));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCombinedBonus({ payload }) {
  try {
    const { campaignId, id, name } = payload;
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Combined,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Combined, id], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slDeleted'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCombinedBonus({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, Campaigns, V2, Combined, Bonuses,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Combined, id]);
    yield put(saveCampaignV2CombinedBonus(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2TriggerConditions({ payload }) {
  try {
    const {
      BonusAdmin, Campaigns, V2, TriggerConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, payload.id, TriggerConditions]);
    yield put(saveCampaignV2TriggerConditions(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCampaignV2TriggerCondition({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    const {
      BonusAdmin, Campaigns, V2, TriggerConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, id, TriggerConditions], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:triggerCondition'), i18n.t('notification:slCreated'), name)));
    yield put(changeCampaignV2TriggerConditionEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCampaignV2TriggerCondition({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name, id } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, TriggerConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, TriggerConditions, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:triggerCondition'), i18n.t('notification:slUpdated'), name)));
    yield put(changeCampaignV2TriggerConditionEndedStatus(true));
    yield put(changeCampaignV2UnitEndedStatus(true));

    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCampaignV2TriggerCondition({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { id, name } = data;
    const {
      BonusAdmin, Campaigns, V2, TriggerConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, TriggerConditions, id]);
    yield put(changeCampaignV2TriggerConditionEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lTriggerCondition'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2TriggerCondition({ payload }) {
  try {
    const { campaignId, id } = payload;

    const {
      BonusAdmin, Campaigns, V2, TriggerConditions,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, campaignId, TriggerConditions, id]);
    yield put(saveCampaignV2TriggerCondition(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createSpecialBonus({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Specials,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Specials], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slCreated'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editSpecialBonus({ payload }) {
  try {
    const { campaignId, data, id } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Specials,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Specials, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:bonus'), i18n.t('notification:slUpdated'))));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteSpecialBonus({ payload }) {
  try {
    const { campaignId, id, name } = payload;
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Specials,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Specials, id], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slDeleted'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSpecialBonus({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, Campaigns, V2, Bonuses, Specials,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, campaignId, Bonuses, Specials, id]);
    yield put(saveCampaignV2SpecialBonus(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* publishCampaignV2({ payload }) {
  try {
    const { id, data, name } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Publish,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, id, Publish], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slPublished'), name)));
    yield put(changeCampaignV2PublishEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2BonusContents({ payload }) {
  try {
    const {
      BonusAdmin, Campaigns, V2, BonusContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, payload.id, BonusContents]);
    yield put(saveCampaignV2BonusContents(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCampaignV2BonusContent({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { bonusName } = data;
    const {
      BonusAdmin, Campaigns, V2, BonusContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, BonusContents], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:contentAndNotification'), i18n.t('notification:slCreated'), bonusName)));
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCampaignV2BonusContent({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { bonusName } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, BonusContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, BonusContents], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:contentAndNotification'), i18n.t('notification:plUpdated'), bonusName)));
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCampaignV2BonusContent({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { bonusId, bonusName } = data;
    const {
      BonusAdmin, Campaigns, V2, BonusContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, BonusContents, bonusId]);
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:contentAndNotification'), i18n.t('notification:slDeleted'), bonusName)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2BonusContent({ payload }) {
  try {
    const { campaignId, id } = payload;

    const {
      BonusAdmin, Campaigns, V2, BonusContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, campaignId, BonusContents, id]);
    yield put(saveCampaignV2BonusContent(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2CampaignContents({ payload }) {
  try {
    const {
      BonusAdmin, Campaigns, V2, Contents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, payload.id, Contents]);
    yield put(saveCampaignV2CampaignContents(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCampaignV2CampaignContent({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { languageId, languageName } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Contents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, Contents, languageId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaignContent'), i18n.t('notification:slCreated'), languageName)));
    yield put(changeCampaignV2CampaignContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCampaignV2CampaignContent({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { languageId, languageName } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Contents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, Contents, languageId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaignContent'), i18n.t('notification:plUpdated'), languageName)));
    yield put(changeCampaignV2CampaignContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCampaignV2CampaignContent({ payload }) {
  try {
    const { campaignId, languageId, data } = payload;
    const { languageName } = data;
    const {
      BonusAdmin, Campaigns, V2, Contents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, Contents, languageId]);
    yield put(changeCampaignV2CampaignContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaignContent'), i18n.t('notification:slDeleted'), languageName)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2CampaignContent({ payload }) {
  try {
    const { id, languageId } = payload;

    const {
      BonusAdmin, Campaigns, V2, Contents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, Contents, languageId]);
    yield put(saveCampaignV2CampaignContent(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2OffersContents({ payload }) {
  try {
    const {
      BonusAdmin, Campaigns, V2, OffersContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, payload.id, OffersContents]);
    yield put(saveCampaignV2OffersContents(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCampaignV2OffersContent({ payload }) {
  try {
    const { campaignId, data, unitName } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, OffersContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, OffersContents], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lContent'), i18n.t('notification:slCreated'), unitName)));
    yield put(changeCampaignV2OffersContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCampaignV2OffersContent({ payload }) {
  try {
    const {
      campaignId, offersContentId, data, unitName,
    } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, OffersContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, OffersContents, offersContentId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lContent'), i18n.t('notification:plUpdated'), unitName)));
    yield put(changeCampaignV2OffersContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCampaignV2OffersContent({ payload }) {
  try {
    const { campaignId, offersContentId, data } = payload;
    const { languageName } = data;
    const {
      BonusAdmin, Campaigns, V2, OffersContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, OffersContents, offersContentId]);
    yield put(changeCampaignV2OffersContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lOfferContent'), i18n.t('notification:slDeleted'), languageName)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2OffersContent({ payload }) {
  try {
    const { id, languageId } = payload;

    const {
      BonusAdmin, Campaigns, V2, OffersContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, OffersContents, languageId]);
    yield put(saveCampaignV2OffersContent(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getUnits({ payload }) {
  try {
    const { id } = payload;
    const {
      BonusAdmin, Campaigns, V2, Units,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, Units], payload);
    yield put(changeLoadingState(false));
    yield put(saveCampaignV2Units(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getUnit({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, Campaigns, V2, Units,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, campaignId, Units, id], payload);
    yield put(changeLoadingState(false));
    yield put(saveCampaignV2Unit(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createUnit({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Units,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, Units], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lUnit'), i18n.t('notification:slCreated'), name)));
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2ParticipationGroupEndedStatus(true));
    yield put(changeCampaignV2TriggerConditionEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editUnit({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name, id } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, Campaigns, V2, Units,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, Campaigns, V2, campaignId, Units, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lUnit'), i18n.t('notification:slUpdated'), name)));
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2ParticipationGroupEndedStatus(true));
    yield put(changeCampaignV2TriggerConditionEndedStatus(true));
    yield put(changeCampaignV2OffersContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteUnit({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { id, name } = data;
    const {
      BonusAdmin, Campaigns, V2, Units,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, Campaigns, V2, campaignId, Units, id]);
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2ParticipationGroupEndedStatus(true));
    yield put(changeCampaignV2TriggerConditionEndedStatus(true));
    yield put(changeCampaignV2OffersContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lUnit'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2NoTriggerStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, V2, NoTrigger, Statistics, Received,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, NoTrigger, Statistics, Received], options);
    yield put(saveCampaignV2Statistics(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* exportCampaignV2NoTriggerStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      BonusAdmin, Campaigns, V2, NoTrigger, Statistics, Received, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, NoTrigger, Statistics, Received, Export], options);
    downloadFile(data, i18n.t('bonus:campaignStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2SignInStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, V2, SignIn, Statistics, Received,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, SignIn, Statistics, Received], options);
    yield put(saveCampaignV2Statistics(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* exportCampaignV2SignInStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      BonusAdmin, Campaigns, V2, SignIn, Statistics, Received, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, SignIn, Statistics, Received, Export], options);
    downloadFile(data, i18n.t('bonus:campaignStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2SignUpStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, V2, SignUp, Statistics, Received,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, SignUp, Statistics, Received], options);
    yield put(saveCampaignV2Statistics(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* exportCampaignV2SignUpStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      BonusAdmin, Campaigns, V2, SignUp, Statistics, Received, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, SignUp, Statistics, Received, Export], options);
    downloadFile(data, i18n.t('bonus:campaignStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2VerificationStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, V2, Verify, Statistics, Received,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, Verify, Statistics, Received], options);
    yield put(saveCampaignV2Statistics(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* exportCampaignV2VerificationStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      BonusAdmin, Campaigns, V2, Verify, Statistics, Received, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, Verify, Statistics, Received, Export], options);
    downloadFile(data, i18n.t('bonus:campaignStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2DepositStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, V2, Deposit, Statistics, Received,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, Deposit, Statistics, Received], options);
    yield put(saveCampaignV2Statistics(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
  }
}

function* exportCampaignV2DepositStatistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      BonusAdmin, Campaigns, V2, Deposit, Statistics, Received, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, Deposit, Statistics, Received, Export], options);
    downloadFile(data, i18n.t('bonus:campaignStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignV2UnitPlayers({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, V2, Players,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, id, Players], options);
    yield put(saveCampaignV2UnitPlayers(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCampaignsV2Units({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, Campaigns, V2, Units,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Campaigns, V2, Units], options);
    yield put(saveCampaignsV2Units(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getFreeBetBonuses({ payload }) {
  const { brandId, params } = payload;
  const options = {
    params,
    paramsSerializer: (param) => qs.stringify(param),
  };
  try {
    const {
      BonusAdmin, Bonus, V2, FreeBet,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Bonus, V2, brandId, FreeBet], options);
    yield put(saveCampaignV2FreeBetBonuses(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSpinBonuses({ payload }) {
  const { brandId, params } = payload;
  const options = {
    params,
    paramsSerializer: (param) => qs.stringify(param),
  };
  try {
    const {
      BonusAdmin, Bonus, V2, Spin,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Bonus, V2, brandId, Spin], options);
    yield put(saveCampaignV2SpinBonuses(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getWagerBonuses({ payload }) {
  const { brandId, params } = payload;
  const options = {
    params,
    paramsSerializer: (param) => qs.stringify(param),
  };
  try {
    const {
      BonusAdmin, Bonus, V2, Combined,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Bonus, V2, brandId, Combined], options);
    yield put(saveCampaignV2WagerBonuses(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getSpecialBonuses({ payload }) {
  const { brandId, params } = payload;
  const options = {
    params,
    paramsSerializer: (param) => qs.stringify(param),
  };
  try {
    const {
      BonusAdmin, Bonus, V2, Special,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, Bonus, V2, brandId, Special], options);
    yield put(saveCampaignV2SpecialBonuses(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCrossPlatformCampaignsV2({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { BonusAdmin, CampaignsCrossPlatform, V2 } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, V2], options);
    yield put(saveCrossPlatformCampaignsV2(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCrossPlatformCampaignV2({ payload }) {
  try {
    const { id } = payload;
    const { BonusAdmin, CampaignsCrossPlatform, V2 } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, V2, id]);
    yield put(saveCrossPlatformCampaignV2(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCrossPlatformCampaignV2({ payload }) {
  try {
    const { name } = payload.data;
    const { BonusAdmin, CampaignsCrossPlatform, V2 } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, CampaignsCrossPlatform, V2], payload);
    const { id } = data;
    yield put(saveCreatedCampaignV2Id(id));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slCreated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCrossPlatformCampaignV2({ payload }) {
  try {
    const { id, data } = payload;
    const { BonusAdmin, CampaignsCrossPlatform, V2 } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, CampaignsCrossPlatform, V2, id], { data });
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:campaign'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCrossPlatformCampaignV2FreeBetBonus({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data: payload.data,
    };
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, FreeBets, Bonuses,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data: responseData } = yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Bonuses, FreeBets], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slCreated'), name)));
    yield put(saveCrossPlatformCampaignV2FreeBetBonus(responseData));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCrossPlatformCampaignV2FreeBetBonus({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, FreeBets, Bonuses,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Bonuses, FreeBets, id]);
    yield put(saveCrossPlatformCampaignV2FreeBetBonus(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCrossPlatformCampaignV2FreeSpinBonus({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Bonuses, Spins,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data: responseData } = yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Bonuses, Spins], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slCreated'), name)));
    if (data.type === enumTypes.campaignV2Bonuses.FreeAmount) {
      yield put(saveCrossPlatformCampaignV2FreeSpinBonus(responseData));
    } else {
      yield put(saveCrossPlatformCampaignV2Unit({ bonusId: responseData.id, name: responseData.name }));
    }
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCrossPlatformCampaignV2FreeSpinBonus({ payload }) {
  try {
    const { campaignId, data, id } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Bonuses, Spins,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Bonuses, Spins, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:bonus'), i18n.t('notification:slUpdated'))));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCrossPlatformCampaignV2FreeSpinBonus({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Bonuses, Spins,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Bonuses, Spins, id]);
    yield put(saveCrossPlatformCampaignV2FreeSpinBonus(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCrossPlatformCampaignV2FreeBetBonus({ payload }) {
  try {
    const { campaignId, id, data } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Bonuses, FreeBets,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Bonuses, FreeBets, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:bonus'), i18n.t('notification:slUpdated'))));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCrossPlatformCampaignV2Bonuses({ payload }) {
  try {
    const { id } = payload;
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Bonuses,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, V2, id, Bonuses], payload);
    yield put(changeLoadingState(false));
    yield put(saveCrossPlatformCampaignV2Bonuses(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCrossPlatformCampaignV2FreeBetBonus({ payload }) {
  try {
    const { campaignId, id, name } = payload;
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Bonuses, FreeBets,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Bonuses, FreeBets, id], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slDeleted'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCrossPlatformCampaignV2FreeSpinBonus({ payload }) {
  try {
    const { campaignId, id, name } = payload;
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Bonuses, Spins,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Bonuses, Spins, id], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slDeleted'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCrossPlatformCampaignV2CombinedBonus({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Combined, Bonuses,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data: responseData } = yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Bonuses, Combined], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slCreated'), name)));
    yield put(saveCrossPlatformCampaignV2Unit({ bonusId: responseData.id, name: responseData.name }));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCrossPlatformCampaignV2CombinedBonus({ payload }) {
  try {
    const { campaignId, id, data } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Bonuses, Combined,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Bonuses, Combined, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:bonus'), i18n.t('notification:slUpdated'))));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCrossPlatformCampaignV2CombinedBonus({ payload }) {
  try {
    const { campaignId, id, name } = payload;
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Bonuses, Combined,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Bonuses, Combined, id], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slDeleted'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCrossPlatformCampaignV2CombinedBonus({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Combined, Bonuses,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Bonuses, Combined, id]);
    yield put(saveCrossPlatformCampaignV2CombinedBonus(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCrossPlatformCampaignV2SpecialBonus({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Bonuses, Specials,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data: responseData } = yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Bonuses, Specials], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slCreated'), name)));
    yield put(saveCrossPlatformCampaignV2SpecialBonus({ id: responseData.id, name: responseData.name }));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCrossPlatformCampaignV2SpecialBonus({ payload }) {
  try {
    const { campaignId, data, id } = payload;
    const options = {
      data,
    };
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Bonuses, Specials,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Bonuses, Specials, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:bonus'), i18n.t('notification:slUpdated'))));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCrossPlatformCampaignV2SpecialBonus({ payload }) {
  try {
    const { campaignId, id, name } = payload;
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Bonuses, Specials,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Bonuses, Specials, id], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lBonus'), i18n.t('notification:slDeleted'), name)));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCrossPlatformCampaignV2SpecialBonus({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Bonuses, Specials,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Bonuses, Specials, id]);
    yield put(saveCrossPlatformCampaignV2SpecialBonus(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCrossPlatformCampaignV2BonusContents({ payload }) {
  try {
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, BonusContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, V2, payload.id, BonusContents]);
    yield put(saveCrossPlatformCampaignV2BonusContents(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCrossPlatformCampaignV2BonusContent({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { bonusName } = data;
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, BonusContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, BonusContents], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:contentAndNotification'), i18n.t('notification:slCreated'), bonusName)));
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCrossPlatformCampaignV2BonusContent({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { bonusName } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, BonusContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, BonusContents], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:contentAndNotification'), i18n.t('notification:plUpdated'), bonusName)));
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCrossPlatformCampaignV2BonusContent({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { bonusId, bonusName } = data;
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, BonusContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, BonusContents, bonusId]);
    yield put(changeCampaignV2BonusContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:contentAndNotification'), i18n.t('notification:slDeleted'), bonusName)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCrossPlatformCampaignV2BonusContent({ payload }) {
  try {
    const { campaignId, id } = payload;

    const {
      BonusAdmin, CampaignsCrossPlatform, V2, BonusContents,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, BonusContents, id]);
    yield put(saveCrossPlatformCampaignV2BonusContent(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCrossPlatformCampaignV2Units({ payload }) {
  try {
    const { id } = payload;
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Units,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, V2, id, Units], payload);
    yield put(changeLoadingState(false));
    yield put(saveCrossPlatformCampaignV2Units(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCrossPlatformCampaignV2Unit({ payload }) {
  try {
    const { campaignId, id } = payload;
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Units,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Units, id], payload);
    yield put(changeLoadingState(false));
    yield put(saveCrossPlatformCampaignV2Unit(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createCrossPlatformCampaignV2Unit({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Units,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Units], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lUnit'), i18n.t('notification:slCreated'), name)));
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCrossPlatformCampaignV2Unit({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name, id } = data;
    const options = {
      data,
    };
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Units,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Units, id], options);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lUnit'), i18n.t('notification:slEdited'), name)));
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2OffersContentEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCrossPlatformCampaignV2Unit({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { id, name } = data;
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Units,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [BonusAdmin, CampaignsCrossPlatform, V2, campaignId, Units, id]);
    yield put(changeCampaignV2UnitEndedStatus(true));
    yield put(changeCampaignV2BonusEndedStatus(true));
    yield put(changeCampaignV2OffersContentEndedStatus(true));
    yield put(changeCampaignV2GeneralConfigEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lUnit'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* publishCrossCampaignCampaignV2({ payload }) {
  try {
    const { id, name } = payload;
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Publish,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, CampaignsCrossPlatform, V2, id, Publish]);
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slPublished'), name)));
    yield put(changeCampaignV2PublishEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* finishCrossPlatformCampaignV2({ payload }) {
  try {
    const { id, name } = payload;
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Finish,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [BonusAdmin, CampaignsCrossPlatform, V2, id, Finish]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:lCampaign'), i18n.t('notification:slFinished'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportCrossPlatformCampaignV2Statistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Statistics, Export,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, V2, id, Statistics, Export], options);
    downloadFile(data, i18n.t('bonus:campaignStatistics'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCrossPlatformCampaignV2Statistics({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      BonusAdmin, CampaignsCrossPlatform, V2, Statistics,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [BonusAdmin, CampaignsCrossPlatform, V2, id, Statistics], options);
    yield put(saveCrossPlatformCampaignV2Statistics(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* cloneCampaignV2({ payload }) {
  try {
    const { campaignId, data } = payload;
    const { name } = data;
    const {
      BonusAdmin, Campaigns, V2, Clone,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data: id } = yield call(NetworkService.makeAPIPostRequest, [BonusAdmin, Campaigns, V2, campaignId, Clone], payload);
    yield put(saveCreatedCampaignV2Id(id));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('promotion:slCampaign'), i18n.t('notification:slCreated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* campaignV2Saga() {
  yield all([
    takeLatest(CampaignV2ActionType.GET_CAMPAIGNS_V2, getCampaigns),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2, getCampaign),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2, createCampaign),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2, editCampaign),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_PARTICIPATION_GROUP, createParticipationGroup),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_PARTICIPATION_GROUP, editParticipationGroup),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_PARTICIPATION_GROUP, getParticipationGroup),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_PARTICIPATION_GROUPS, getParticipationGroups),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_PARTICIPATION_GROUP, deleteParticipationGroup),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_FREE_BET_BONUS, createFreeBetBonus),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_FREE_BET_BONUS, editFreeBetBonus),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_FREE_BET_BONUS, getFreeBetBonus),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_FREE_SPIN_BONUS, createFreeSpinBonus),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_FREE_SPIN_BONUS, editFreeSpinBonus),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_FREE_SPIN_BONUS, getFreeSpinBonus),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_BONUSES, getBonuses),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_FREE_BET_BONUS, deleteFreeBetBonus),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_FREE_SPIN_BONUS, deleteFreeSpinBonus),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_TRIGGER_CONDITIONS, getCampaignV2TriggerConditions),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_TRIGGER_CONDITION, createCampaignV2TriggerCondition),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_TRIGGER_CONDITION, editCampaignV2TriggerCondition),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_TRIGGER_CONDITION, deleteCampaignV2TriggerCondition),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_TRIGGER_CONDITION, getCampaignV2TriggerCondition),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_SPECIAL_BONUS, createSpecialBonus),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_SPECIAL_BONUS, editSpecialBonus),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_SPECIAL_BONUS, deleteSpecialBonus),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_SPECIAL_BONUS, getSpecialBonus),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_COMBINED_BONUS, getCombinedBonus),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_COMBINED_BONUS, createCombinedBonus),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_COMBINED_BONUS, editCombinedBonus),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_COMBINED_BONUS, deleteCombinedBonus),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_BONUS_CONTENTS, getCampaignV2BonusContents),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_BONUS_CONTENT, createCampaignV2BonusContent),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_BONUS_CONTENT, editCampaignV2BonusContent),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_BONUS_CONTENT, deleteCampaignV2BonusContent),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_BONUS_CONTENT, getCampaignV2BonusContent),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_CAMPAIGN_CONTENTS, getCampaignV2CampaignContents),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_CAMPAIGN_CONTENT, createCampaignV2CampaignContent),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_CAMPAIGN_CONTENT, editCampaignV2CampaignContent),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_CAMPAIGN_CONTENT, deleteCampaignV2CampaignContent),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_CAMPAIGN_CONTENT, getCampaignV2CampaignContent),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_UNITS, getUnits),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_UNIT, getUnit),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_UNIT, createUnit),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_UNIT, editUnit),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_UNIT, deleteUnit),
    takeLatest(CampaignV2ActionType.PUBLISH_CAMPAIGN_V2, publishCampaignV2),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_OFFERS_CONTENTS, getCampaignV2OffersContents),
    takeLatest(CampaignV2ActionType.CREATE_CAMPAIGN_V2_OFFERS_CONTENT, createCampaignV2OffersContent),
    takeLatest(CampaignV2ActionType.EDIT_CAMPAIGN_V2_OFFERS_CONTENT, editCampaignV2OffersContent),
    takeLatest(CampaignV2ActionType.DELETE_CAMPAIGN_V2_OFFERS_CONTENT, deleteCampaignV2OffersContent),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_OFFERS_CONTENT, getCampaignV2OffersContent),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_NO_TRIGGER_STATISTICS, getCampaignV2NoTriggerStatistics),
    takeLatest(CampaignV2ActionType.EXPORT_CAMPAIGN_V2_NO_TRIGGER_STATISTICS, exportCampaignV2NoTriggerStatistics),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_SIGN_IN_STATISTICS, getCampaignV2SignInStatistics),
    takeLatest(CampaignV2ActionType.EXPORT_CAMPAIGN_V2_SIGN_IN_STATISTICS, exportCampaignV2SignInStatistics),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_SIGN_UP_STATISTICS, getCampaignV2SignUpStatistics),
    takeLatest(CampaignV2ActionType.EXPORT_CAMPAIGN_V2_SIGN_UP_STATISTICS, exportCampaignV2SignUpStatistics),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_VERIFICATION_STATISTICS, getCampaignV2VerificationStatistics),
    takeLatest(CampaignV2ActionType.EXPORT_CAMPAIGN_V2_VERIFICATION_STATISTICS, exportCampaignV2VerificationStatistics),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_DEPOSIT_STATISTICS, getCampaignV2DepositStatistics),
    takeLatest(CampaignV2ActionType.EXPORT_CAMPAIGN_V2_DEPOSIT_STATISTICS, exportCampaignV2DepositStatistics),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGNS_V2_UNITS, getCampaignsV2Units),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_UNIT_PLAYERS, getCampaignV2UnitPlayers),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_FREE_BET_BONUSES, getFreeBetBonuses),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_SPIN_BONUSES, getSpinBonuses),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_WAGER_BONUSES, getWagerBonuses),
    takeLatest(CampaignV2ActionType.GET_CAMPAIGN_V2_SPECIAL_BONUSES, getSpecialBonuses),
    takeLatest(CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGNS_V2, getCrossPlatformCampaignsV2),
    takeLatest(CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2, getCrossPlatformCampaignV2),
    takeLatest(CampaignV2ActionType.CREATE_CROSS_PLATFORM_CAMPAIGN_V2, createCrossPlatformCampaignV2),
    takeLatest(CampaignV2ActionType.EDIT_CROSS_PLATFORM_CAMPAIGN_V2, editCrossPlatformCampaignV2),
    takeLatest(CampaignV2ActionType.CREATE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS, createCrossPlatformCampaignV2FreeBetBonus),
    takeLatest(CampaignV2ActionType.EDIT_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS, editCrossPlatformCampaignV2FreeBetBonus),
    takeLatest(CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS, getCrossPlatformCampaignV2FreeBetBonus),
    takeLatest(CampaignV2ActionType.DELETE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_BET_BONUS, deleteCrossPlatformCampaignV2FreeBetBonus),
    takeLatest(CampaignV2ActionType.CREATE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS, createCrossPlatformCampaignV2FreeSpinBonus),
    takeLatest(CampaignV2ActionType.EDIT_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS, editCrossPlatformCampaignV2FreeSpinBonus),
    takeLatest(CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS, getCrossPlatformCampaignV2FreeSpinBonus),
    takeLatest(CampaignV2ActionType.DELETE_CROSS_PLATFORM_CAMPAIGN_V2_FREE_SPIN_BONUS, deleteCrossPlatformCampaignV2FreeSpinBonus),
    takeLatest(CampaignV2ActionType.CREATE_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS, createCrossPlatformCampaignV2SpecialBonus),
    takeLatest(CampaignV2ActionType.EDIT_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS, editCrossPlatformCampaignV2SpecialBonus),
    takeLatest(CampaignV2ActionType.DELETE_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS, deleteCrossPlatformCampaignV2SpecialBonus),
    takeLatest(CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_SPECIAL_BONUS, getCrossPlatformCampaignV2SpecialBonus),
    takeLatest(CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS, getCrossPlatformCampaignV2CombinedBonus),
    takeLatest(CampaignV2ActionType.CREATE_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS, createCrossPlatformCampaignV2CombinedBonus),
    takeLatest(CampaignV2ActionType.EDIT_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS, editCrossPlatformCampaignV2CombinedBonus),
    takeLatest(CampaignV2ActionType.DELETE_CROSS_PLATFORM_CAMPAIGN_V2_COMBINED_BONUS, deleteCrossPlatformCampaignV2CombinedBonus),
    takeLatest(CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_BONUSES, getCrossPlatformCampaignV2Bonuses),
    takeLatest(CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_UNITS, getCrossPlatformCampaignV2Units),
    takeLatest(CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_UNIT, getCrossPlatformCampaignV2Unit),
    takeLatest(CampaignV2ActionType.CREATE_CROSS_PLATFORM_CAMPAIGN_V2_UNIT, createCrossPlatformCampaignV2Unit),
    takeLatest(CampaignV2ActionType.EDIT_CROSS_PLATFORM_CAMPAIGN_V2_UNIT, editCrossPlatformCampaignV2Unit),
    takeLatest(CampaignV2ActionType.DELETE_CROSS_PLATFORM_CAMPAIGN_V2_UNIT, deleteCrossPlatformCampaignV2Unit),
    takeLatest(CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENTS, getCrossPlatformCampaignV2BonusContents),
    takeLatest(CampaignV2ActionType.CREATE_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT, createCrossPlatformCampaignV2BonusContent),
    takeLatest(CampaignV2ActionType.EDIT_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT, editCrossPlatformCampaignV2BonusContent),
    takeLatest(CampaignV2ActionType.DELETE_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT, deleteCrossPlatformCampaignV2BonusContent),
    takeLatest(CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_BONUS_CONTENT, getCrossPlatformCampaignV2BonusContent),
    takeLatest(CampaignV2ActionType.PUBLISH_CROSS_PLATFORM_CAMPAIGN_V2, publishCrossCampaignCampaignV2),
    takeLatest(CampaignV2ActionType.FINISH_CROSS_PLATFORM_CAMPAIGN_V2, finishCrossPlatformCampaignV2),
    takeLatest(CampaignV2ActionType.GET_CROSS_PLATFORM_CAMPAIGN_V2_STATISTICS, getCrossPlatformCampaignV2Statistics),
    takeLatest(CampaignV2ActionType.EXPORT_CROSS_PLATFORM_CAMPAIGN_V2_STATISTICS, exportCrossPlatformCampaignV2Statistics),
    takeLatest(CampaignV2ActionType.CLONE_CAMPAIGN_V2, cloneCampaignV2),
  ]);
}
