import enumTypes from 'constants/enumTypes';
import { title } from 'constants/tabs/title';

const playerStatusActionMap = {
  [enumTypes.playerStatuses.Active]: enumTypes.playerActions.Unblock,
  [enumTypes.playerStatuses.Blocked]: enumTypes.playerActions.BlockAccount,
  [enumTypes.playerStatuses.BlockedForSport]: enumTypes.playerActions.BlockSportsbook,
  [enumTypes.playerStatuses.BlockedForCasino]: enumTypes.playerActions.BlockCasino,
  [enumTypes.playerStatuses.BlockedForTransactions]: enumTypes.playerActions.BlockTransactions,
  [enumTypes.playerStatuses.BlockedForWithdrawals]: enumTypes.playerActions.BlockWithdrawals,
  [enumTypes.playerStatuses.BlockedForDeposits]: enumTypes.playerActions.BlockDeposits,
};

const paymentColors = {
  [enumTypes.paymentRequestStatuses.Pending]: '#fa8c16',
  [enumTypes.paymentRequestStatuses.InProcess]: '#fa8c16',
  [enumTypes.paymentRequestStatuses.CancelledByUser]: '#e20a16',
  [enumTypes.paymentRequestStatuses.CancelledByPlayer]: '#e20a16',
  [enumTypes.paymentRequestStatuses.Failed]: '#e20a16',
  [enumTypes.paymentRequestStatuses.Declined]: '#e20a16',
  [enumTypes.paymentRequestStatuses.Frozen]: '#e20a16',
  [enumTypes.paymentRequestStatuses.PayPending]: '#fa8c16',
  [enumTypes.paymentRequestStatuses.Paid]: '#52c41a',
  [enumTypes.paymentRequestStatuses.OnHold]: '#fa791c',
  [enumTypes.paymentRequestStatuses.Initialized]: '#1890ff',
};

const betStateColors = {
  [enumTypes.betStates.New]: '#1890ff',
  [enumTypes.betStates.Won]: '#52c41a',
  [enumTypes.betStates.Lost]: '#e20a16',
  [enumTypes.betStates.Cancelled]: '#e20a16',
  5: '#e20a16',
  6: '#e20a16',
  7: '#e20a16',
};

const legalAgreementStatusesColors = {
  [enumTypes.legalAgreementVersionStatuses.Pending]: '#fa8c16',
  [enumTypes.legalAgreementVersionStatuses.Current]: '#1890ff',
  [enumTypes.legalAgreementVersionStatuses.Old]: '#e20a16',
};

const kycDocumentTypesOptions = {
  [enumTypes.brandVerificationDocumentTypes.Passport]: enumTypes.kycDocumentOptions.Passport,
  [enumTypes.brandVerificationDocumentTypes.IdCard]: enumTypes.kycDocumentOptions.IDCard,
  [enumTypes.brandVerificationDocumentTypes.DrivingLicense]: enumTypes.kycDocumentOptions.DrivingLicense,
  [enumTypes.brandVerificationDocumentTypes.Selfie]: enumTypes.kycDocumentOptions.Selfie,
  [enumTypes.brandVerificationDocumentTypes.BankCard]: enumTypes.kycDocumentOptions.BankCard,
  [enumTypes.brandVerificationDocumentTypes.UtilityBills]: enumTypes.kycDocumentOptions.UtilityBills,
  [enumTypes.brandVerificationDocumentTypes.TaxBills]: enumTypes.kycDocumentOptions.TaxBills,
  [enumTypes.brandVerificationDocumentTypes.BankStatement]: enumTypes.kycDocumentOptions.BankStatement,
  [enumTypes.brandVerificationDocumentTypes.RentAgreement]: enumTypes.kycDocumentOptions.RentAgreement,
  [enumTypes.brandVerificationDocumentTypes.InsuranceAgreement]: enumTypes.kycDocumentOptions.InsuranceAgreement,
  [enumTypes.brandVerificationDocumentTypes.ResidencyCard]: enumTypes.kycDocumentOptions.ResidencyCard,
  [enumTypes.brandVerificationDocumentTypes.PaymentMethod]: enumTypes.kycDocumentOptions.PaymentMethod,
  [enumTypes.brandVerificationDocumentTypes.Other]: enumTypes.kycDocumentOptions.Other,
};

const kycDocumentStateColors = {
  [enumTypes.kycDocumentStates.Pending]: '#fa8c16',
  [enumTypes.kycDocumentStates.Approved]: '#1890ff',
  [enumTypes.kycDocumentStates.Rejected]: '#e20a16',
  [enumTypes.kycDocumentStates.Verified]: '#52c41a',
  [enumTypes.kycDocumentStates.Archived]: '#8d939c',
  [enumTypes.kycDocumentStates.Expired]: '#722ed1',
  [enumTypes.kycDocumentStates.InProcess]: '#fa8c16',
};

const bonusStatusesColors = {
  [enumTypes.bonusStatuses.Scheduled]: '#fa8c16',
  [enumTypes.bonusStatuses.Active]: '#1890ff',
  [enumTypes.bonusStatuses.Canceled]: '#e20a16',
  [enumTypes.bonusStatuses.PromotionCanceled]: '#e20a16',
  [enumTypes.bonusStatuses.Finished]: '#722ed1',
  [enumTypes.bonusStatuses.Pending]: '#fadb14',
  [enumTypes.bonusStatuses.PartialCancelled]: '#e20a16',
  [enumTypes.bonusStatuses.InProcess]: '#fadb14',
};

const playerBonusStatusesColors = {
  [enumTypes.playerBonusStatuses.New]: '#52c41a',
  [enumTypes.playerBonusStatuses.Active]: '#1890ff',
  [enumTypes.playerBonusStatuses.Finished]: '#722ed1',
  [enumTypes.playerBonusStatuses.Canceled]: '#e20a16',
  [enumTypes.playerBonusStatuses.Expired]: '#fa791c',
  [enumTypes.playerBonusStatuses.Pending]: '#fa8c16',
  [enumTypes.playerBonusStatuses.Paused]: '#fa791c',
};

const bonusCommonStatusesColors = {
  [enumTypes.bonusCommonStatuses.Scheduled]: '#fa8c16',
  [enumTypes.bonusCommonStatuses.Active]: '#1890ff',
  [enumTypes.bonusCommonStatuses.Disabled]: '#e20a16',
  [enumTypes.bonusCommonStatuses.Finished]: '#722ed1',
};

const domainsStateColors = {
  [enumTypes.domainStatuses.Pending]: '#fa8c16',
  [enumTypes.domainStatuses.Active]: '#52c41a',
  [enumTypes.domainStatuses.Failed]: '#e20a16',
  [enumTypes.domainStatuses.Verified]: '#1890ff',
};

const conversionRouteKey = {
  [title.brandSetting.deposit]: 'depositLogs',
  [title.brandSetting.withdrawal]: 'withdrawalLogs',
  [title.brandSetting.other]: 'otherLogs',
};

const dashboardWidgetIcons = {
  [enumTypes.dashboardWidgetChartType.LineChart]: 'LineChartIcon',
  [enumTypes.dashboardWidgetChartType.BarChart]: 'BarChartIcon',
  [enumTypes.dashboardWidgetChartType.ColumnChart]: 'ColumnChartIcon',
  [enumTypes.dashboardWidgetChartType.PieChart]: 'PieChartIcon',
  [enumTypes.dashboardWidgetChartType.AreaChart]: 'AreaChartIcon',
  [enumTypes.dashboardWidgetChartType.StackedBarChart]: 'StackedBarChartIcon',
  [enumTypes.dashboardWidgetChartType.NumericWidget]: 'NumericWidgetIcon',
};

const dashboardIconsTitle = {
  [enumTypes.dashboardIcons.FinancialIcon]: 'mapping:financial',
  [enumTypes.dashboardIcons.AnalyticsIcon]: 'mapping:analytics',
  [enumTypes.dashboardIcons.PlayersIcon]: 'mapping:players',
  [enumTypes.dashboardIcons.CasinoIcon]: 'mapping:casino',
  [enumTypes.dashboardIcons.SportTennisIcon]: 'mapping:sport',
  [enumTypes.dashboardIcons.OtherIcon]: 'mapping:other',
  [enumTypes.dashboardIcons.BonusEngineIcon]: 'mapping:bonus',
};

const dashboardRouteKey = {
  Customers: 'player/aggregation',
  Betting: 'betReport/aggregation',
  Payments: 'paymentReport/aggregation',
  Bonuses: 'bonus/aggregation',
  FinancialTrends: 'betReport/aggregation/financial',
  CustomerTrends: 'player/aggregation/customers',
  Share: 'share',
};

const dashboardRouteKeyOptions = {
  [dashboardRouteKey.Customers]: 'mapping:customers',
  [dashboardRouteKey.Betting]: 'mapping:betting',
  [dashboardRouteKey.Payments]: 'mapping:payments',
  [dashboardRouteKey.Bonuses]: 'mapping:bonuses',
  [dashboardRouteKey.FinancialTrends]: 'mapping:financialTrends',
  [dashboardRouteKey.CustomerTrends]: 'mapping:customerTrends',
  [dashboardRouteKey.Share]: 'mapping:share',
};

const paymentRequestActionStatusMap = {
  StartProcess: enumTypes.paymentRequestStatuses.InProcess,
  Cancel: enumTypes.paymentRequestStatuses.CancelledByUser,
  Decline: enumTypes.paymentRequestStatuses.Declined,
  Freeze: enumTypes.paymentRequestStatuses.Frozen,
  Pay: enumTypes.paymentRequestStatuses.Paid,
};

const playerBlockControlOptions = {
  Account: enumTypes.playerStatuses.Blocked,
  Sportsbook: enumTypes.playerStatuses.BlockedForSport,
  Casino: enumTypes.playerStatuses.BlockedForCasino,
  Withdrawals: enumTypes.playerStatuses.BlockedForWithdrawals,
  Transactions: enumTypes.playerStatuses.BlockedForTransactions,
  Deposits: enumTypes.playerStatuses.BlockedForDeposits,
};

const playerBlockedStatuses = {
  BlockAccount: enumTypes.playerStatuses.Blocked,
  BlockForSportsbook: enumTypes.playerStatuses.BlockedForSport,
  BlockForCasino: enumTypes.playerStatuses.BlockedForCasino,
  BlockForWithdrawals: enumTypes.playerStatuses.BlockedForWithdrawals,
  BlockForTransactions: enumTypes.playerStatuses.BlockedForTransactions,
  BlockForDeposits: enumTypes.playerStatuses.BlockedForDeposits,
};

const smsProviders = {
  [enumTypes.smsProviders.AfricasTalking]: 'AfricasTalking',
  [enumTypes.smsProviders.Gateway]: 'Gateway',
  [enumTypes.smsProviders.Twilio]: 'Twilio',
  [enumTypes.smsProviders.Blackbox]: 'Blackbox',
  [enumTypes.smsProviders.MobiPace]: 'MobiPace',
  [enumTypes.smsProviders.Nikita]: 'Nikita',
  [enumTypes.smsProviders.Dexatel]: 'Dexatel',
  [enumTypes.smsProviders.Orchard]: 'Orchard',
  [enumTypes.smsProviders.BudgetSMS]: 'BudgetSMS',
  [enumTypes.smsProviders.SmartSMSSolutions]: 'SmartSMSSolutions',
  [enumTypes.smsProviders.betaSMS]: 'Beta SMS',
  [enumTypes.smsProviders.RPMMobile]: 'RPM Mobile',
  [enumTypes.smsProviders.InfoBip]: 'Infobip',
  [enumTypes.smsProviders.iCell]: 'iCell',
};

const campaignConfigureModes = {
  bonus: 1,
  participant: 2,
  bonusContent: 3,
  campaignContent: 4,
  triggerCondition: 5,
};

const campaignBonusTypesIcons = {
  [enumTypes.allBonusTypes.FreeBet]: 'CasinoIcon',
  [enumTypes.allBonusTypes.WagerStandard]: 'StandardWagerIcon',
  [enumTypes.allBonusTypes.WagerReal]: 'RealWagerIcon',
  [enumTypes.allBonusTypes.SportWagering]: 'SportStandardWagerIcon',
  [enumTypes.allBonusTypes.SportFreeBet]: 'BetIcon',
  [enumTypes.allBonusTypes.SportRealWagering]: 'SportRealWagerIcon',
  [enumTypes.allBonusTypes.RealMoney]: 'SuccessfulDepositsAmountIcon',
  [enumTypes.allBonusTypes.FreeSpin]: 'FreeSpinIcon',
  [enumTypes.allBonusTypes.FreeAmount]: 'FreeAmountIcon',
};

const reasonsType = {
  [enumTypes.reasonsType.Default]: 'mapping:default',
  [enumTypes.reasonsType.Custom]: 'mapping:custom',
  [enumTypes.reasonsType.System]: 'mapping:system',
};

const sportsBookLayoutTypesIconMapping = {
  [enumTypes.sportsBookLayoutTypes.Standard]: 'StandardViewIcon',
  [enumTypes.sportsBookLayoutTypes.African]: 'AfricaIcon',
  [enumTypes.sportsBookLayoutTypes.Latin]: 'LatinIcon',
  [enumTypes.sportsBookLayoutTypes.Asian]: 'AsiaIcon',
};

const bonusTypes = {
  [enumTypes.bonuses.Casino]: {
    FreeBet: enumTypes.allBonusTypes.FreeBet,
    StandardWager: enumTypes.allBonusTypes.WagerStandard,
    RealWager: enumTypes.allBonusTypes.WagerReal,
  },
  [enumTypes.bonuses.Spin]: {
    FreeSpin: enumTypes.allBonusTypes.FreeSpin,
    FreeAmount: enumTypes.allBonusTypes.FreeAmount,
  },
  [enumTypes.bonuses.Sport]: {
    FreeBet: enumTypes.allBonusTypes.SportFreeBet,
    StandardWager: enumTypes.allBonusTypes.SportWagering,
    RealWager: enumTypes.allBonusTypes.SportRealWagering,
  },
  [enumTypes.bonuses.Special]: {
    RealMoney: enumTypes.allBonusTypes.RealMoney,
  },
};

const seoPageGroups = {
  [enumTypes.seoPageGroups.Seo]: 'mapping:pages',
  [enumTypes.seoPageGroups.RulesSeo]: 'mapping:rules',
  [enumTypes.seoPageGroups.Promotion]: 'mapping:promotions',
};

const promoModes = {
  generalConfig: 'generalConfig',
  spinDistribution: 'spinDistribution',
  playingParticipants: 'playingParticipants',
  prize: 'prize',
  machine: 'machine',
  groupLimit: 'groupLimit',
  machineConfig: 'machineConfig',
  content: 'content',
  promotionContent: 'promotionContent',
  spinDistributionContent: 'spinDistributionContent',
  exchangeCenter: 'exchangeCenter',
  prizeContent: 'prizeContent',
};

const promotionMachineTypeIcon = {
  [enumTypes.promotionMachineType.Wheel]: 'WheelIcon',
  [enumTypes.promotionMachineType.Grid]: 'GridPrizeIcon',
  [enumTypes.promotionMachineType.Box]: 'MysteryBoxIcon',
  // [enumTypes.promotionMachineType.Cube]: 'FreeSpinIcon',
};

const promotionContentTypeIcon = {
  [enumTypes.promotionContentGenerationType.Promotion]: 'PromotionIcon',
  [enumTypes.promotionContentGenerationType.Machine]: 'MysteryBoxIcon',
  [enumTypes.promotionContentGenerationType.SpinDistribution]: 'SpinDistributionIcon',
  [enumTypes.promotionContentGenerationType.Prize]: 'BonusesIcon',
};

const promotionStatusColors = {
  [enumTypes.promotionStatus.Draft]: '#8d939c',
  [enumTypes.promotionStatus.Scheduled]: '#fa8c16',
  [enumTypes.promotionStatus.Active]: '#1890ff',
  [enumTypes.promotionStatus.Finished]: '#722ed1',
};

const promotionalItemStatusColors = {
  [enumTypes.promotionalItemStatus.Active]: '#1890ff',
  [enumTypes.promotionalItemStatus.Finished]: '#722ed1',
};

const sportsBookFeaturesWallets = {
  jackpots: 'mapping:jackpots',
  multiCashback: 'mapping:multiCashback',
  tournaments: 'mapping:tournaments',
  ultraCashback: 'mapping:ultraCashback',
  groupStage: 'mapping:groupStage',
  boreDraw: 'mapping:boreDraw',
};

const casinoFeaturesWallets = {
  jackpots: 'mapping:jackpots',
  tournaments: 'mapping:tournaments',
};

const exportReportStatesColors = {
  [enumTypes.exportReportStates.InQueue]: '#1890ff',
  [enumTypes.exportReportStates.Generating]: '#fadb14',
  [enumTypes.exportReportStates.Generated]: '#52c41a',
  [enumTypes.exportReportStates.Expired]: '#8d939c',
  [enumTypes.exportReportStates.Cancelled]: '#fa791c',
  [enumTypes.exportReportStates.Failed]: '#e20a16',
};

const playerPrizeStatusesColors = {
  [enumTypes.promotionPrizeStatus.New]: '#fa8c16',
  [enumTypes.promotionPrizeStatus.Received]: '#52c41a',
  [enumTypes.promotionPrizeStatus.Cancelled]: '#e20a16',
  [enumTypes.promotionPrizeStatus.Pending]: '#fadb14',
};

const permissionSecurityLevelIcons = {
  [enumTypes.permissionSecurityLevel.Lowest]: 'PermissionLevel1Icon',
  [enumTypes.permissionSecurityLevel.Low]: 'PermissionLevel2Icon',
  [enumTypes.permissionSecurityLevel.Medium]: 'PermissionLevel3Icon',
  [enumTypes.permissionSecurityLevel.High]: 'PermissionLevel4Icon',
  [enumTypes.permissionSecurityLevel.Higher]: 'PermissionLevel5Icon',
  [enumTypes.permissionSecurityLevel.VeryHigh]: 'PermissionLevel6Icon',
  [enumTypes.permissionSecurityLevel.Maximum]: 'PermissionLevel7Icon',
};

const spinDistributionTriggerTypeAmount = {
  [enumTypes.promotionSpinDistributingTypes.Deposit]: 'mapping:depositAmount',
  [enumTypes.promotionSpinDistributingTypes.Bet]: 'mapping:betAmount',
};

const bulkBetCorrectionStatusesColors = {
  [enumTypes.bulkBetCorrectionStatuses.InProgress]: '#fa791c',
  [enumTypes.bulkBetCorrectionStatuses.Failed]: '#e20a16',
  [enumTypes.bulkBetCorrectionStatuses.Success]: '#52c41a',
};

const locales = {
  en: 'en',
  es: 'es',
  tr: 'tr',
  ru: 'ru',
  pt: 'pt-br',
  zh: 'zh-cn',
  ro: 'ro',
  bg: 'bg',
  el: 'el',
};

const promotionContentTypesModesMapping = {
  [enumTypes.promotionContentGenerationType.Machine]: promoModes.content,
  [enumTypes.promotionContentGenerationType.Promotion]: promoModes.promotionContent,
  [enumTypes.promotionContentGenerationType.SpinDistribution]: promoModes.spinDistributionContent,
  [enumTypes.promotionContentGenerationType.Prize]: promoModes.prizeContent,
};

const notificationPreferencesIcons = {
  [enumTypes.notificationPreferences.Deposit]: 'BrandDepositsIcon',
  [enumTypes.notificationPreferences.Withdrawal]: 'WithdrawalsIcon',
  [enumTypes.notificationPreferences.Promotion]: 'PromotionIcon',
  [enumTypes.notificationPreferences.Bonuses]: 'BonusesIcon',
};

const releaseNoteLabelsColor = {
  [enumTypes.releaseNoteLabels.Bug]: '#E20A16',
  [enumTypes.releaseNoteLabels.Improvement]: '#722ED1',
  [enumTypes.releaseNoteLabels.Feature]: '#52C41A',
};

const crossCampaignUsageStatusColors = {
  [enumTypes.crossCampaignSourceStatuses.Active]: '#1890ff',
  [enumTypes.crossCampaignSourceStatuses.Finished]: '#722ed1',
};

const autoRefreshPeriods = {
  10000: 'tenSec',
  30000: 'thirtySec',
  60000: 'oneMin',
  300000: 'fiveMin',
  600000: 'tenMin',
};

const campaignModes = {
  generalConfig: 'generalConfig',
  participationGroups: 'participationGroups',
  bonuses: 'bonuses',
  triggerCondition: 'triggerCondition',
  bonusContent: 'bonusContent',
  campaignContent: 'campaignContent',
  units: 'units',
  offersContent: 'offersContent',
};

const campaignV2BonusTypesIcons = {
  [enumTypes.campaignV2Bonuses.FreeBetSport]: 'BetIcon',
  [enumTypes.campaignV2Bonuses.FreeBetCasino]: 'CasinoIcon',
  [enumTypes.campaignV2Bonuses.FreeSpin]: 'FreeSpinIcon',
  [enumTypes.campaignV2Bonuses.FreeAmount]: 'FreeAmountIcon',
  [enumTypes.campaignV2Bonuses.WagerCombined]: 'RealWagerIcon',
  [enumTypes.campaignV2Bonuses.RealMoney]: 'FreeAmountIcon',
};

const campaignV2StatusColors = {
  [enumTypes.campaignV2Statuses.Scheduled]: '#fa8c16',
  [enumTypes.campaignV2Statuses.Active]: '#1890ff',
  [enumTypes.campaignV2Statuses.Finished]: '#722ed1',
  [enumTypes.campaignV2Statuses.Pending]: '#fadb14',
  [enumTypes.campaignV2Statuses.InProcess]: '#fadb14',
};

const campaignV2BonusTypes = {
  [enumTypes.campaignV2BonusFamilies.FreeBet]: {
    FreeBetCasino: enumTypes.campaignV2Bonuses.FreeBetCasino,
    FreeBetSport: enumTypes.campaignV2Bonuses.FreeBetSport,
  },
  [enumTypes.campaignV2BonusFamilies.Spin]: {
    FreeSpin: enumTypes.campaignV2Bonuses.FreeSpin,
    FreeAmount: enumTypes.campaignV2Bonuses.FreeAmount,
  },
};

const brandAutomationAccountFieldsLocaleKeys = {
  Citizenship: 'citizenship',
};

const brandAutomationAccountFieldLocaleMapping = {
  [enumTypes.brandAutomationAccountFields.Citizenship]: brandAutomationAccountFieldsLocaleKeys.Citizenship,
};

const inboxMessageStatusColors = {
  [enumTypes.inboxStatuses.New]: '#52c41a',
  [enumTypes.inboxStatuses.SendByUser]: '#52c41a',
  [enumTypes.inboxStatuses.ReadByPlayer]: '#1890ff',
  [enumTypes.inboxStatuses.Deleted]: '#e20a16',
};

const notificationTemplateStatusColors = {
  [enumTypes.templateStatus.Ready]: '#1890ff',
  [enumTypes.templateStatus.Draft]: '#8d939c',
};

const messageStatusColors = {
  [enumTypes.pushNotificationStatuses.Sent]: '#52c41a',
  [enumTypes.pushNotificationStatuses.Failed]: '#e20a16',
  [enumTypes.pushNotificationStatuses.Scheduled]: '#fa8c16',
  [enumTypes.pushNotificationStatuses.Sending]: '#fa791c',
  [enumTypes.pushNotificationStatuses.Preparing]: '#fadb14',
};

const playerComplaintStatusColors = {
  [enumTypes.playerComplaintStatuses.New]: '#52c41a',
  [enumTypes.playerComplaintStatuses.Replied]: '#fadb14',
};

export {
  playerStatusActionMap,
  paymentColors,
  betStateColors,
  legalAgreementStatusesColors,
  kycDocumentTypesOptions,
  kycDocumentStateColors,
  bonusStatusesColors,
  domainsStateColors,
  bonusCommonStatusesColors,
  conversionRouteKey,
  dashboardWidgetIcons,
  dashboardRouteKey,
  dashboardRouteKeyOptions,
  dashboardIconsTitle,
  paymentRequestActionStatusMap,
  playerBlockControlOptions,
  playerBlockedStatuses,
  smsProviders,
  campaignConfigureModes,
  campaignBonusTypesIcons,
  reasonsType,
  playerBonusStatusesColors,
  sportsBookLayoutTypesIconMapping,
  bonusTypes,
  seoPageGroups,
  promoModes,
  promotionMachineTypeIcon,
  promotionContentTypeIcon,
  promotionStatusColors,
  promotionalItemStatusColors,
  sportsBookFeaturesWallets,
  casinoFeaturesWallets,
  exportReportStatesColors,
  playerPrizeStatusesColors,
  permissionSecurityLevelIcons,
  bulkBetCorrectionStatusesColors,
  locales,
  spinDistributionTriggerTypeAmount,
  promotionContentTypesModesMapping,
  notificationPreferencesIcons,
  releaseNoteLabelsColor,
  crossCampaignUsageStatusColors,
  autoRefreshPeriods,
  campaignModes,
  campaignV2BonusTypesIcons,
  campaignV2StatusColors,
  campaignV2BonusTypes,
  brandAutomationAccountFieldsLocaleKeys,
  brandAutomationAccountFieldLocaleMapping,
  inboxMessageStatusColors,
  notificationTemplateStatusColors,
  playerComplaintStatusColors,
  messageStatusColors,
};
